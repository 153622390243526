
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/components/AlbumsGOGOOI/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://e72e679ae3614e728ffef05a9d27e663@sentry.wixpress.com/384',
      id: 'e72e679ae3614e728ffef05a9d27e663',
      projectName: 'albums-ooi',
      teamName: 'exposure',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'AlbumsGOGOOI'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
