import { HOUR } from '../consts/generalConsts';
import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter/dist/src/server';
import { getGogAlbums } from './gogApi';
import {
  getDefaultAlbums,
  getTemplateDefaultAlbums,
} from './defaultAlbumsService';
import uuid from 'uuid';

export const convertCoverToGalleryFormat = (cover: GogPhoto) => {
  const isSecuredCover = cover.metadata && cover.metadata.isSecure;

  return {
    id: cover.photoId,
    dataType: 'Photo',
    name: cover.url,
    mediaUrl: `https://static.wixstatic.com/media/${cover.url}`,
    photoMetadata: cover.metadata
      ? {
          height: cover.metadata.height,
          width: cover.metadata.width,
        }
      : { height: 300, width: 300 },
    isSecure: isSecuredCover,
    imageToken: isSecuredCover && (cover.imageToken || cover.token),
  };
};

export const getPresetFontIfNeeded = (
  type: string,
  textObj: any,
  styleParams: any,
  textPresets: any,
) => {
  if (styleParams[type + 'Font']) {
    return null;
  } else {
    const textSizeType = textObj[type];
    const font = textPresets[textSizeType];
    return font.value.substring(5, font.value.length - 1);
  }
};

export const getTextsObject = (
  width: number,
  textPresets: any,
  styleParams: any,
) => {
  const detailsText = [
    // < 160 no font
    { title: 'Body-M', date: 'Body-S', count: 'Body-S' }, // 160 - 280
    { title: 'Body-L', date: 'Body-M', count: 'Body-S' }, // 281 - 700
    { title: 'Heading-S', date: 'Body-L', count: 'Body-S' }, // 701 - 980
    { title: 'Heading-M', date: 'Body-L', count: 'Body-M' }, // > 980
  ];
  if (!textPresets) {
    return;
  }

  let idx,
    albumClass = '';
  if (width < 160) {
    // no text
    idx = -1;
    albumClass = 'none';
  } else if (width < 180) {
    // 160-180
    idx = 0;
    albumClass = 'small';
  } else if (width < 220) {
    // 180-220
    idx = 0;
    albumClass = 'small2';
  } else if (width < 280) {
    // 220-280
    idx = 0;
    albumClass = 'small3';
  } else if (width < 340) {
    // 280-340
    idx = 1;
    albumClass = 'small3';
  } else if (width < 440) {
    // 340 - 440
    albumClass = 'medium';
    idx = 1;
  } else if (width < 700) {
    // 440 -700
    albumClass = 'big';
    idx = 1;
  } else if (width < 980) {
    // 700 - 980
    albumClass = 'xl';
    idx = 2;
  } else {
    // > 980
    albumClass = 'xxl';
    idx = 3;
  }

  if (idx < 0) {
    return { albumClass };
  }

  const textObj = detailsText[idx];

  return {
    title: getPresetFontIfNeeded('title', textObj, styleParams, textPresets),
    titleSize: textPresets[textObj.title].size,
    date: getPresetFontIfNeeded('date', textObj, styleParams, textPresets),
    dateSize: textPresets[textObj.date].size,
    countSize: textPresets[textObj.count].size,
    albumClass,
  };
};

const isPremiumWithNewSubdomain = (album: GogAlbum) => {
  return (
    album.publishedLayout &&
    album.publishedLayout.subDomainDate &&
    album.publishedLayout.subDomainDate + HOUR > new Date().getTime()
  );
};

export const getLink = (album: GogAlbum) => {
  let link;
  if (isPremiumWithNewSubdomain(album)) {
    link = album.publishedLayout.link;
  } else if (album.liveLink) {
    link = album.liveLink;
  } else if (album.publishedLayout.link) {
    link = album.publishedLayout.link;
  }
  return link;
};

export const parseAlbumsCovers = (albums: GogAlbum[]) => {
  let albumsCovers: GalleryItem[] = [];
  const coverIdToAlbum: { [id: string]: GogAlbum } = {};
  const coverPhotosForGallery: GogPhotoForGallery[] = [];
  albums.forEach((album: any) => {
    try {
      let coverPhoto;
      const oldCover = album.sets[0].photos[0];
      if (oldCover) {
        coverPhoto = convertCoverToGalleryFormat(oldCover);
      } else {
        coverPhoto = {
          id: uuid(),
          dataType: 'Photo',
          name:
            'https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png',
          mediaUrl: `https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png`,
          photoMetadata: { height: 300, width: 300 },
          isSecure: false,
        };
      }
      if (coverPhoto.id) {
        coverPhotosForGallery.push(coverPhoto);
        coverIdToAlbum[coverPhoto.id] = album;
      }
    } catch (e) {
      console.log('Failed to convert album cover of album ' + album.id);
    }
  });

  albumsCovers = serverItemsToProGallery(coverPhotosForGallery);
  albumsCovers.forEach((item: any, idx: number) => {
    try {
      const watermark = albums[idx].settings.watermark;
      item.metaData.title = albums[idx].editables.title;
      item.metaData.description = albums[idx].editables.date;
      item.orderIndex = albums[idx].orderIndex;
      if (watermark && coverPhotosForGallery[idx].isSecure) {
        item.metaData.watermark = watermark;
      }
    } catch (e) {
      try {
        console.log('Failed to parse album data ' + albums[idx].albumId);
      } catch (err) {}
    }
  });
  return { albumsCovers, coverIdToAlbum };
};

export const getGogDataAndCovers = async (
  instanceId: string,
  instance: string,
  compId: string,
  defaultAlbumsIds: string[],
) => {
  const gogData = await getGogAlbums(instanceId, instance, compId);
  if (!gogData.albums || gogData.albums.length === 0) {
    if (defaultAlbumsIds && defaultAlbumsIds.length > 0) {
      gogData.albums = await getTemplateDefaultAlbums(
        defaultAlbumsIds,
        instance,
      );
    } else {
      gogData.albums = getDefaultAlbums();
    }
  }

  const { albumsCovers, coverIdToAlbum } = parseAlbumsCovers(gogData.albums);
  return { gogData, albumsCovers, coverIdToAlbum };
};
